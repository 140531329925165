import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import media from 'styled-media-query';

export const Footer = styled.footer`
  padding: 80px 40px 0;

  ${media.lessThan('large')`
    padding: 50px 20px 0;
  `}
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan('large')`
    flex-direction: column;
  `}
`;

export const FooterWrapper = styled.div``;

export const Title = styled.h1`
  ${media.lessThan('large')`
    padding-top: 20px;
  `}

  font-size: 18px;
  font-family: var(--second-font);
  color: var(--second-color);
  letter-spacing: 4px;
  text-transform: uppercase;
  padding-bottom: 15px;
`;

export const SubTitle = styled.h2`
  font-size: 14px;
  font-style: italic;
  font-family: var(--first-font);
  color: var(--third-color);
`;

export const InfoTitle = styled.span`
  font-size: 14px;
  display: block;
  font-style: italic;
  font-family: var(--first-font);
  color: var(--first-color);
  padding-bottom: 5px;
`;

export const InfoText = styled.span`
  font-size: 14px;
  display: block;
  font-style: italic;
  font-family: var(--first-font);
  color: var(--second-color);
  padding-bottom: 5px;
`;

export const Copyright = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 70px;
  padding-bottom: 15px;

  ${media.lessThan('large')`
    flex-direction: column;
  `}
`;

export const CopyText = styled.span`
  font-size: 14px;
  display: block;
  font-family: var(--first-font);
  color: var(--first-color);
  text-transform: uppercase;

  ${media.lessThan('large')`
    padding-bottom: 20px;
    text-align: center;
  `}
`;

export const CopyLogoLink = styled(AniLink)`
  font-size: 0;
`;

export const CopyLogo = styled.img`
  width: 100px;
`;
