import styled from 'styled-components';
import media from 'styled-media-query';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export const MenuLinksWrapper = styled.nav`
  ${media.lessThan('medium')`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
    display: none;
    background: rgb(22, 25, 30, 0.9);
    margin: 0px;

    &.-active {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `}
`;

export const MenuList = styled.ul`
  display: flex;

  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`;

export const MenuListItem = styled.li`
  &:not(:last-child) {
    margin-right: 25px;
  }

  ${media.lessThan('medium')`

  &:not(:last-child) {
    margin-right: 0;
  }

    &:not(:first-child) {
      margin-top: 20px;
    }
  `}
`;

export const MenuLink = styled(AniLink)`
  font-size: 18px;
  font-family: var(--second-font);
  color: var(--first-color);
  text-transform: uppercase;
  letter-spacing: 3px;

  &.active {
    color: var(--second-color);
  }

  ${media.lessThan('medium')`
    font-size: 26px;
  `}
`;

export const MenuLinkContact = styled.span`
  font-size: 18px;
  font-family: var(--second-font);
  color: var(--first-color);
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;

  ${media.lessThan('medium')`
    font-size: 26px;
  `}
`;
