import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import media from 'styled-media-query';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  padding: 0 40px;
  position: relative;
  z-index: 2;

  ${media.lessThan('medium')`
    padding: 0 20px;
  `};
`;

export const RedeSocialWrapper = styled.div`
  position: absolute;
  top: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;

  ${media.lessThan('medium')`
    left: 2px;
  `};
`;

export const RedeSocialLink = styled(AniLink)`
  font-size: 0;
`;

export const FacebookImage = styled.img`
  width: 15px;
  margin-top: 20px;
`;

export const InstagramImage = styled.img`
  width: 24px;
`;
