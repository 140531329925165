import styled from 'styled-components';
import media from 'styled-media-query';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label``;

export const Input = styled.input`
  ${media.lessThan('large')`
    height: 50px;
  `}

  background-color: transparent;
  border: 1px solid var(--first-color);
  height: 30px;
  padding: 3px 10px;
  width: 100%;
  margin-top: 10px;
  color: var(--second-color);
  font-family: var(--first-font);
  font-style: italic;
  font-size: 14px;

  &::placeholder {
    color: var(--second-color);
    font-family: var(--first-font);
    font-style: italic;
    font-size: 14px;
  }
`;

export const TextArea = styled.textarea`
  background-color: transparent;
  border: 1px solid var(--first-color);
  height: 90px;
  padding: 3px 10px;
  width: 100%;
  margin-top: 10px;
  color: var(--second-color);
  font-family: var(--first-font);
  font-style: italic;
  font-size: 14px;

  &::placeholder {
    color: var(--second-color);
    font-family: var(--first-font);
    font-style: italic;
    font-size: 14px;
  }
`;

export const LabelButton = styled.label`
  position: relative;
  text-align: right;

  &:after {
    content: '';
    position: absolute;
    background-color: var(--first-color);
    width: 55px;
    height: 1px;
    right: 2px;
    bottom: -4px;
  }
`;

export const InputButton = styled.input`
  background-color: transparent;
  border: none;
  color: var(--first-color);
  font-style: italic;
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
  cursor: pointer;
  padding: 0;
`;
