import React from 'react';

import * as S from './styles';

const MenuBurger = ({ onClick, className = '' }) => {
  return (
    <S.Container onClick={onClick}>
      <S.MenuBurguerIcon className={`${className}`}>
        <S.IconBurguerFirst>
          <S.IconBurguerSecond />
        </S.IconBurguerFirst>
      </S.MenuBurguerIcon>
    </S.Container>
  );
};

export default MenuBurger;
