import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export const LogoAvatar = styled.img`
  width: 150px;
`;

export const LogoAvatarLink = styled(AniLink)`
  font-size: 0;
`;
