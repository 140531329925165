import React from 'react';

import * as S from './styles';

const MenuLinks = ({ className = '' }) => {
  const handleClickContact = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };
  return (
    <S.MenuLinksWrapper className={`${className}`}>
      <S.MenuList>
        <S.MenuListItem>
          <S.MenuLink
            cover
            direction="left"
            bg="#16191E"
            duration={0.6}
            to="/"
            activeClassName="active"
          >
            Home
          </S.MenuLink>
        </S.MenuListItem>
        <S.MenuListItem>
          <S.MenuLink
            cover
            direction="right"
            bg="#16191E"
            duration={0.6}
            to="/sobre"
            activeClassName="active"
          >
            Sobre
          </S.MenuLink>
        </S.MenuListItem>
        <S.MenuListItem>
          <S.MenuLink
            cover
            direction="right"
            bg="#16191E"
            duration={0.6}
            to="/empresas"
            activeClassName="active"
          >
            Empresas
          </S.MenuLink>
        </S.MenuListItem>
        <S.MenuListItem>
          <S.MenuLinkContact href="#" onClick={handleClickContact}>
            Contato
          </S.MenuLinkContact>
        </S.MenuListItem>
      </S.MenuList>
    </S.MenuLinksWrapper>
  );
};

export default MenuLinks;
