import React from 'react';
import PropTypes from 'prop-types';
import { TransitionPortal } from 'gatsby-plugin-transition-link';

import Header from '../Header';
import Footer from '../Footer';

import * as S from './styles';

import GlobalStyles from '../../styles/global';

const Layout = ({ children }) => (
  <S.LayoutMain>
    <GlobalStyles />
    <Header />
    {children}
    <Footer />
  </S.LayoutMain>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
