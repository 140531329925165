import React, { useState } from 'react';

import Logo from '../Logo';
import MenuLinks from '../MenuLinks';
import MenuBurger from '../MenuBurguer';

import Facebook from '../../../static/facebook.png';
import Instagram from '../../../static/instagram.png';

import * as S from './styles';

const Header = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  const handleClick = () => {
    setActiveMenu(!activeMenu);
  };

  MenuBurger.handleClickOutside = () => setActiveMenu(false);

  return (
    <S.Container>
      <Logo />
      <MenuLinks className={activeMenu ? '-active' : ' '} />
      <MenuBurger
        onClick={handleClick}
        className={activeMenu ? '-active' : ' '}
      />
      <S.RedeSocialWrapper>
        <S.RedeSocialLink
          cover
          direction="left"
          bg="#FFF"
          duration={0.6}
          to="/instagram"
        >
          Instagram
          <S.InstagramImage src={Instagram} alt="Instagram" />
        </S.RedeSocialLink>
        <S.RedeSocialLink
          cover
          direction="left"
          bg="#FFF"
          duration={0.6}
          to="/facebook"
        >
          Facebook
          <S.FacebookImage src={Facebook} alt="Facebook" />
        </S.RedeSocialLink>
      </S.RedeSocialWrapper>
    </S.Container>
  );
};

export default Header;
