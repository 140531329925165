import styled from 'styled-components';
import media from 'styled-media-query';

export const LayoutMain = styled.main`
  min-height: 100vh;
  width: 100%;

  ${media.lessThan('large')`
    padding: 0;
  `}
`;
