import React from 'react';

import LogoImage from '../../../static/logo.png';
import { LogoAvatar, LogoAvatarLink } from './styles';

const Logo = () => {
  return (
    <LogoAvatarLink cover direction="left" bg="#FFF" duration={0.6} to="/">
      Logo
      <LogoAvatar src={LogoImage} alt="Centro empresarial Montechiari" />
    </LogoAvatarLink>
  );
};
export default Logo;
