import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.button`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    color: rgb(255, 255, 255);
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    padding: 0;
  `}
`;

export const MenuBurguerIcon = styled.div`
  margin-top: 3px;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  perspective: 80px;
  overflow: visible;

  &.-active {
    span > span {
      background-color: transparent !important;

      &:before {
        transform: translate3d(0px, 7px, 0px) rotate(45deg);
      }

      &:after {
        transform: translate3d(0px, -7px, 0px) rotate(-45deg);
      }
    }
  }
`;

export const IconBurguerFirst = styled.span`
  width: 24px;
  height: 20px;
  display: inline-block;
  position: relative;
`;

export const IconBurguerSecond = styled.span`
  width: 24px;
  height: 3px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  border-radius: 20px;
  display: block;
  top: 50%;
  margin-top: -2px;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
    background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;

  &::before,
  &:after {
    content: '';
    display: block;
    transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
    width: 24px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    border-radius: 20px;
  }

  &:before {
    top: -7px;
  }

  &:after {
    bottom: -7px;
  }
`;
