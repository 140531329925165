import React from 'react';

import LogoOasis from '../../../static/oasis.png';

import Form from '../Form';
import FormNews from '../FormNews';

import * as S from './styles';

const Footer = () => {
  return (
    <S.Footer>
      <S.Container>
        <S.FooterWrapper>
          <S.Title>Contato</S.Title>
          <S.InfoTitle>Tel:</S.InfoTitle>
          <S.InfoText>22 99951-1777</S.InfoText>
          <S.InfoTitle>E-Mail:</S.InfoTitle>
          <S.InfoText>contato@cem.com.br</S.InfoText>
          <S.InfoTitle>Endereço:</S.InfoTitle>
          <S.InfoText>Duas Pedras</S.InfoText>
        </S.FooterWrapper>
        <S.FooterWrapper>
          <S.Title>Fale Conosco</S.Title>
          <S.SubTitle>
            Preencha os campos abaixo e envie-nos sua mensagem
          </S.SubTitle>
          <Form />
        </S.FooterWrapper>
        <S.FooterWrapper>
          <S.Title>Fique por dentro</S.Title>
          <S.SubTitle>
            Assine nossa Newsletter e não perca nenhuma novidade
          </S.SubTitle>
          <FormNews />
        </S.FooterWrapper>
      </S.Container>
      <S.Copyright>
        <S.CopyText>
          © 2020 - Centro Empresarial Montechiari. Todos os direitos reservados.
        </S.CopyText>
        <S.CopyLogoLink cover direction="left" bg="#FFF" duration={0.6} to="/">
          Logo Oásis
          <S.CopyLogo src={LogoOasis} alt="Desenvolvimento Agência Oásis" />
        </S.CopyLogoLink>
      </S.Copyright>
    </S.Footer>
  );
};

export default Footer;
