import React, { Component } from 'react';
import axios from 'axios';

import * as S from './styles';

class Form extends Component {
  constructor() {
    super();

    this.state = {
      nome: '',
      telefone: '',
      email: '',
      mensagem: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async handleSubmit(e) {
    e.preventDefault();

    const { nome, telefone, email, mensagem } = this.state;

    const form = await axios.post(
      'https://oasis-form-backend.herokuapp.com/api/form',
      {
        nome,
        telefone,
        email,
        mensagem,
      }
    );
  }

  render() {
    return (
      <S.Form onSubmit={this.handleSubmit}>
        <S.Label>
          <S.Input
            type="text"
            name="nome"
            placeholder="Nome:"
            onChange={this.handleChange}
          ></S.Input>
        </S.Label>
        <S.Label>
          <S.Input
            type="text"
            name="telefone"
            placeholder="Telefone:"
            onChange={this.handleChange}
          ></S.Input>
        </S.Label>
        <S.Label>
          <S.Input
            type="email"
            name="email"
            placeholder="Email:"
            onChange={this.handleChange}
          ></S.Input>
        </S.Label>
        <S.Label>
          <S.TextArea
            name="mensagem"
            placeholder="Mensagem:"
            onChange={this.handleChange}
          ></S.TextArea>
        </S.Label>
        <S.LabelButton>
          <S.InputButton type="submit" value="enviar" />
        </S.LabelButton>
      </S.Form>
    );
  }
}

export default Form;
