import React from 'react';

import * as S from './styles';

const FormNews = () => {
  return (
    <S.Form id="contact-form" action="/contact" method="POST">
      <S.Label>
        <S.Input type="email" name="name" placeholder="Email:"></S.Input>
      </S.Label>
      <S.LabelButton>
        <S.InputButton type="submit" value="enviar" />
      </S.LabelButton>
    </S.Form>
  );
};

export default FormNews;
